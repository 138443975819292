<template>
  <div style="background-color: #EBEED7;">
   <section>
      <article
        style="padding-top: 160px;">
      
        <div class="container" style="margin: 50px auto; text-align: left">
          <div class="dining_introduction">
            <p class="locus_word_style">
                    <small>KINAWATAKA WOMEN INITIATIVES</small>
                  </p>
            <h2 style="font-weight: 600; font-size: 2em">
              The Process Of Recycling Straws.
            </h2>
            <p style="">
                The unique straw products are made from used plastic straws, 
                which the women and youths collect, wash, sterilize and dry in shade. 
                The straws are flattened and woven at their homes, which mean they can still care 
                for their children or weave while at their market stall. There are 10 steps taken 
                to make straw products and are as below.
            </p>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="mb-5">
        <div class="container">
            <ol>
                <li>
                    <div class="process_grid">
                        <div>
                            <div>
                                <h2 style="font-weight: 600; font-size: 2em">
                                   1.) Collecting used straws wherever they are found
                                </h2>
                                <p style="font-weight: 600; font-size: 1.2em">
                                    These straws were our major raw material .Women and youth collect 
                                    straws from different places for example drainage channels, 
                                    soda depots etc. Straws are readily available given that they are 
                                    largely viewed as waste
                                </p>
                            </div>
                        </div>
                        <div>
                            <div class="process_grid_image">
                                <img src="../assets/images/straws22.jpg" width="100%">
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="process_grid">
                        <div>
                            <div class="process_grid_image">
                                <img src="../assets/images/sorting_straws.png" width="100%">
                            </div>
                        </div>
                        <div>
                            <div>
                                <h2 style="font-weight: 600; font-size: 2em">
                                    2.) Sorting straws in different colors 
                                </h2>
                                <p style="font-weight: 600; font-size: 1.2em">
                                    We arrange the used straws into different colors to give them 
                                    a good and easy designing of the mats, remove the damaged straws 
                                    then neatly arrange them in sacks. This is done well since most 
                                    of the collected straws are either  red or blue.
                                </p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="process_grid">
                        <div>
                            <div>
                                <h2 style="font-weight: 600; font-size: 2em">
                                    3.) Soaking the straws
                                </h2>
                                <p style="font-weight: 600; font-size: 1.2em">
                                    After sorting the used straws, we soak them.  
                                    Straws are soaked in cans filled with water, detergents (Ariel), 
                                    and jik for 2-3 days to kill germs and quicken washing.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div class="process_grid_image">
                                <img src="../assets/images/soaking_straws.png" width="100%">
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="process_grid">
                        <div>
                            <div class="process_grid_image">
                                <img src="../assets/images/washing_straws.png" width="100%">
                            </div>
                        </div>
                        <div>
                            <div>
                                <h2 style="font-weight: 600; font-size: 2em">
                                    4.) Washing and drying of the straws
                                </h2>
                                <p style="font-weight: 600; font-size: 1.2em">
                                    We then wash the straws using basins, Ariel, soap and a sponge. 
                                    Members are encouraged to take special attention in washing the straws 
                                    so that the straws are washed very clean both inside and out. Straws 
                                    that would not get clean are removed. The straws are put under shade 
                                    in order to dry
                                </p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="process_grid">
                        <div>
                            <div>
                                <h2 style="font-weight: 600; font-size: 2em">
                                    5.) Pressing the straws
                                </h2>
                                <p style="font-weight: 600; font-size: 1.2em">
                                    The next step is to press the straws flat and straight using knives. 
                                    Members are encouraged to avoid cracking the straws so that weaving 
                                    goes on well. This was very tiresome to the women since it damages 
                                    their hands.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div class="process_grid_image">
                                <img src="../assets/images/pressing_straws.png" width="100%">
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="process_grid">
                        <div>
                            <div class="process_grid_image">
                                <img src="../assets/images/weaving_straws.png" width="100%">
                            </div>
                        </div>
                        <div>
                            <div>
                                <h2 style="font-weight: 600; font-size: 2em">
                                    6.) Weaving the mats
                                </h2>
                                <p style="font-weight: 600; font-size: 1.2em">
                                    Women have been taught how to weave mats since it is out of the mats 
                                    that other products are made. Weaving is done using well pressed straws 
                                    which are flat and straight .This was done using hands. Women were 
                                    encouraged to tighten, straighten and maintain weaving of their desired 
                                    designs of the mats or of a particular order as per customer preference. 
                                </p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="process_grid">
                        <div>
                            <div>
                                <h2 style="font-weight: 600; font-size: 2em">
                                    7.) Trimming
                                </h2>
                                <p style="font-weight: 600; font-size: 1.2em">
                                    Trimming is done using a pair scissors, after weaving to make the panels ready 
                                    for joining. This is carefully done  in order not to damage the mats.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div class="process_grid_image">
                                <img src="../assets/images/trimming_straws.png" width="100%">
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="process_grid">
                        <div>
                            <div class="process_grid_image">
                                <img src="../assets/images/joining_straws.png" width="100%">
                            </div>
                        </div>
                        <div>
                            <div>
                                <h2 style="font-weight: 600; font-size: 2em">
                                    8) Joining
                                </h2>
                                <p style="font-weight: 600; font-size: 1.2em">
                                    This is done when panels are trimmed and they were joined to make a mat. 
                                    We use joining needles at this stage.
                                </p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="process_grid">
                        <div>
                            <div>
                                <h2 style="font-weight: 600; font-size: 2em">
                                    9.) Cutting
                                </h2>
                                <p style="font-weight: 600; font-size: 1.2em">
                                    Cutting is done after the panels are joined to make a mat. 
                                    We use a hot knife and a panga. Different products have different 
                                    measurements and these were taken using a tape measure.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div class="process_grid_image">
                                <img src="../assets/images/cutting_straws.png" width="100%">
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="process_grid">
                        <div>
                            <div class="process_grid_image">
                                <img src="../assets/images/stitching_straws.png" width="100%">
                            </div>
                        </div>
                        <div>
                            <div>
                                <h2 style="font-weight: 600; font-size: 2em">
                                    10.) Stitching
                                </h2>
                                <p style="font-weight: 600; font-size: 1.2em">
                                    At this stage, the cut pieces are joined together using a sewing 
                                    machine to make the products. Strips are added onto the pieces 
                                    to make bags.
                                </p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="process_grid">
                        <div>
                            <div>
                                <h2 style="font-weight: 600; font-size: 2em">
                                    11.) Labeling and finishing
                                </h2>
                                <p style="font-weight: 600; font-size: 1.2em">
                                    This is done during stitching to be able to identify the product and also have contact details.
                                </p>
                            </div>
                        </div>
                        <div>
                            <div class="process_grid_image">
                                <img src="../assets/images/labeling.png" width="100%">
                            </div>
                        </div>
                    </div>
                </li>
            </ol>
        </div>
      </article>
    </section>
    
  </div>
</template>

<script>

export default {

  data() {
    return {
      table_drawer: false,
      direction: "rtl",

        proce: [
        {
          id: "1",
          room_code: "Kookaburra",
          title: "Kookaburra Cottage",
          room_price: 250,
          description:
            "Ideal for Families or long staying guests, the Kookaburra Cottage features three bed rooms en-suite, a cosy lounge, separate dining area and a kitchen.",
          images: [
            require("../assets/images/products/mat.jpg"),
            require("../assets/images/products/mat.jpg"),
            require("../assets/images/products/mat.jpg"),
          ],
        },
        {
          id: "2",
          room_code: "Deluxe",
          title: "Deluxe Room",
          room_price: 60,
          description:
            "Spacious Deluxe Rooms are elegantly appointed and feature views of the hotel’s water gardens from both the room and private balcony. Furnished with one king or two twin beds",
          images: [
            require("../assets/images/products/mat.jpg"),
            require("../assets/images/products/mat.jpg"),
            require("../assets/images/products/mat.jpg"),
          ],
        },
        {
          id: "3",
          title: "Executive Room",
          room_code: "Executive",
          room_price: 80,
          description:
            "Executive Rooms offer large king size beds and garden views from the private balconies. Ideal for business stays, this room category features an expansive workspace and a relaxation area with a comfortable couch and coffee table.",
          images: [
            require("../assets/images/products/mat.jpg"),
            require("../assets/images/products/mat.jpg"),
            require("../assets/images/products/mat.jpg"),
          ],
        },
      ],

    };
  },

  methods: {
    handleClose(done) {
      done();
    },

    verifyPhoneNUmber() {
      this.$router.push({ path: `verify-phone-number` });
    },

    accommodationDetail(roomId) {
      this.$router.push({ path: `accommodation-detail/${roomId}` });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.locus_word_style {
    font-size: 0.5em; font-weight: 300;
    word-spacing: 8px;
    letter-spacing: 5px;
  }
.dining_introduction {
  width: 80%;
}
ol {
    list-style: none;
    padding: 0px;;
}
.process_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 10px;
    margin-bottom: 30px;
}
.process_grid>div {
    background-color: white;
    padding: 20px;
    display: flex;
    align-items: center;
    
 }
 .process_grid>div:first-child {
  border-radius: 10px 0px 0px 10px;
 }
 .process_grid>div:nth-child(2) {
  border-radius: 0px 10px 10px 0px;
 }
 .process_grid > div .process_grid_image {
     width: 100%;
 }

.process_grid > div .process_grid_image img {
  height: 400px;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.goBtn {
  color: white;
  font-weight: 600;
  transition: 0.5s;
}

.process_grid > div:hover .goBtn {
  margin-left: 20px;
  transition: 0.5s;
}

  .btn-brown {
    background-color: #d96b12;
    color: white;
    width: 200px;
  }
  .btn-view-more {
    background-color: white;
    border: 1px solid #d96b12;
    width: 250px;
  }

.objectives_section ul{
  list-style: none;
  padding: 0px;
}

.objectives_section ul li{
  font-size: 1.2em;
  padding-top: 30px;
  padding-bottom: 10px;
  /* background-color: white; */
  border-bottom: 1px solid white;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .process_grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .process_grid > div .process_grid_image img {
    height: 200px;
    width: 100%;
  }

  .dining_introduction {
    width: 100%;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .process_grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .process_grid > div .process_grid_image img {
    height: 200px;
    width: 100%;
  }

  .dining_introduction {
    width: 100%;
  }
}
</style>
